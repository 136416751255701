define('frontend-app/pods/forgot-password/route', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({

		intl: Ember.inject.service(),

		titleToken: Ember.computed(function () {
			return this.get('intl').t("accounts.signin.forgot");
		}),

		actions: {
			requestPasswordReset: function requestPasswordReset(email) {

				var self = this;
				self.controller.set('error', false);
				self.controller.set('success', false);

				(0, _ajax.default)(_environment.default.base_url + '/api/accounts/reset_password_request?email=' + email, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'X-AppId': _environment.default.apiServer.headers['X-AppId']
					},
					credentials: 'include'
				}).then(function (data) {

					self.controller.set('success', true);
					self.controller.set('message', self.get('intl').t(data.key, data.params || {}));
				}).catch(function (err) {

					console.log(err);
					err.json().then(function (data) {
						self.controller.set('error', true);
						self.controller.set('message', self.get('intl').t(data.key, data.params || {}));
					});
				});
			}
		}

	});
});
define('frontend-app/pods/careers/view/route', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model(params) {

			return (0, _ajax.default)(_environment.default.base_url + '/api/data/occupations/' + params.slug, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'X-AppId': _environment.default.apiServer.headers['X-AppId']
				},
				credentials: 'include'
			});
		},


		afterModel: function afterModel(model) {
			this.setTitleToken(model);
			this.setHeadTags(model);
		},

		setTitleToken: function setTitleToken(model) {
			this.set('titleToken', model.data.name_en);
		},

		setHeadTags: function setHeadTags(model) {
			var headTags = [{
				type: 'meta',
				attrs: {
					property: 'og:title',
					content: model.data.name_en
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:description',
					content: "Find employment that puts your skills to use."
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:type',
					content: 'article'
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:image',
					content: _environment.default.base_url + '/assets/CCAA-logo-og.png'
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:url',
					content: _environment.default.base_url + '/careers/' + model.data.code
				}
			}];

			this.set('headTags', headTags);
		}
	});
});
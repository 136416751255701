define('frontend-app/pods/account/index/route', ['exports', 'frontend-app/config/environment', 'fetch'], function (exports, _environment, _fetch) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend( /*AuthenticatedRouteMixin,*/{

		account: Ember.inject.service(),
		occupations: Ember.inject.service(),

		model: function model() {
			// need to get user account and determine which self assessments they have access to
			// then need to fetch any existing self assessments related to this user
			// non-existing ones appear down below, existing ones appear either as in progress or completed
			return Ember.RSVP.Promise.all([
			//fetch( (config.base_url + "/api/assessments/list"), {credentials: 'include'}),
			(0, _fetch.default)(_environment.default.base_url + "/api/activity_feed", {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'X-AppId': _environment.default.apiServer.headers['X-AppId']
				},
				credentials: 'include'
			}).then(function (response) {
				return response.json();
			}), (0, _fetch.default)(_environment.default.base_url + "/api/assessments/user", {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'X-AppId': _environment.default.apiServer.headers['X-AppId']
				},
				credentials: 'include'
			}).then(function (response) {
				return response.json();
			}), this.get('occupations').getData('occupations')]).then(function (data) {
				return {
					//assessments: data[0],//.slice(0, 5),
					activity: data[0],
					user_assessments: data[1]
				};
			}).catch(function (err) {
				console.log(err);
			});
		}
	});
});
define('frontend-app/services/occupations', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		downloadUrl: Ember.computed(function () {
			return _environment.default.base_url;
		}),
		all_occupations: [],

		occupation_lookup: Ember.computed("all_occupations", function () {
			return this.get('all_occupations').reduce(function (prev, current, list) {
				prev[current.value.code] = current.value.name_en;
				return prev;
			}, {});
		}),

		image_lookup: Ember.computed("all_occupations", function () {
			var _this = this;

			return this.get('all_occupations').reduce(function (prev, current, list) {
				prev[current.value.code] = {
					hero: current.value.filename_hero ? _this.get('downloadUrl') + '/api/download/' + current.id + '/' + current.value.filename_hero : '',
					tile: current.value.filename_tile ? _this.get('downloadUrl') + '/api/download/' + current.id + '/' + current.value.filename_tile : '/assets/elec_instrument_component_technician.jpg'
				};
				return prev;
			}, {});
		}),

		init: function init() {
			this._super.apply(this, arguments);
			var self = this;

			this.getData('occupations');
		},
		getData: function getData(which) {
			var self = this;

			if (which == 'occupations') {

				if (this.get('all_occupations.length') === 0) {
					return (0, _ajax.default)(_environment.default.base_url + '/api/data/occupations', {
						method: 'GET',
						headers: {
							'Content-Type': 'application/json',
							'X-AppId': _environment.default.apiServer.headers['X-AppId']
						},
						credentials: 'include'
					}).then(function (data) {
						self.set('all_occupations', data);
						return data;
					});
				} else {
					return new Ember.RSVP.Promise(function (resolve, reject) {
						resolve(self.get('all_occupations'));
					});
				}
			}
		}
	});
});
define('frontend-app/router', ['exports', 'frontend-app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,

    headData: Ember.inject.service(),

    setTitle: function setTitle(title) {
      this.get('headData').set('title', title);
    }
  });

  Router.map(function () {
    this.route('about');
    this.route('browse');
    this.route('careers', function () {
      this.route('view', { path: "/:slug" });
    });
    this.route('sign-up');
    this.route('questions');
    this.route('contact');
    this.route('sign-in');
    this.route('forgot-password');
    this.route('reset-password');
    this.route('account', function () {
      this.route('edit');
      this.route('transactions');
    });
    this.route('assessments', function () {
      this.route('view', { path: "/:code" });
    });
    this.route('activate-account');
    this.route('site-map');
  });

  exports.default = Router;
});
define('frontend-app/pods/site-map/controller', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    router: Ember.inject.service('router'),

    routeNames: Ember.computed(function () {
      var router = this.get('router');
      return router._router._routerMicrolib.recognizer.names;
    })
  });
});
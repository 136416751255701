define('frontend-app/pods/assessments/view/controller', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		account: Ember.inject.service(),
		fastboot: Ember.inject.service(),
		scroller: Ember.inject.service(),
		occupations: Ember.inject.service(),

		isSaving: false,
		isModified: false,

		currentSection: 0,
		sectionIndex: Ember.computed('currentSection', function () {
			return this.get('currentSection') - 1;
		}),
		currentSlide: 0,
		updateFlag: 0,

		slide_is_completed: [],

		// METADATA

		assessment: Ember.computed('model', function () {
			if (!this.get('model')) return {};
			var assessment = this.get('model.assessment');
			assessment.data.contents.unshift({
				title: "Introduction",
				slides: [{
					description: "Please read these instructions below before beginning your assessment."
				}]
			});
			return assessment;
		}),

		response: [{
			slides: [{ completed: true }]
		}],

		totalSections: Ember.computed('assessment', function () {
			return this.get('assessment.data.contents.length');
		}),

		totalQuestions: Ember.computed('model', function () {
			var tally = 0;
			this.get('model.questions').forEach(function (section) {
				section.forEach(function (slide) {
					tally += slide.length;
				});
			});
			return tally;
		}),

		totalAnswered: Ember.computed('model', 'updateFlag', function () {
			var tally = 0;
			this.get('model.responses').forEach(function (section) {
				section.forEach(function (slide) {
					slide.forEach(function (answer) {
						if (answer === null) return;
						if (answer.value === null) return;
						if (Ember.isArray(answer.value) && answer.value.length == 0) return;
						tally++;
					});
				});
			});
			return tally;
		}),

		progress: Ember.computed('totalQuestions', 'totalAnswered', function () {
			return this.get('totalAnswered') / this.get('totalQuestions');
		}),

		remaining: Ember.computed('totalQuestions', 'totalAnswered', function () {
			return this.get('totalQuestions') - this.get('totalAnswered');
		}),

		progress_display: Ember.computed('progress', function () {
			return Math.trunc(this.get('progress') * 1000) / 10;
		}),

		currentSectionSlideCount: Ember.computed('assessment', 'currentSection', function () {
			return this.get('assessment.data.contents')[this.get('currentSection')].slides.length;
		}),

		// ALIASES

		questionLookup: Ember.computed('model', function () {
			var lookup = this.get('model.questions').reduce(function (prev, current, list) {
				prev[current.key] = current.value;
				return prev;
			}, {});
			return lookup;
		}),

		sectionTitle: Ember.computed('currentSection', function () {
			return this.get('assessment.data.contents')[this.get('currentSection')].title || "";
		}),

		slideTitle: Ember.computed('currentSection', 'currentSlide', function () {
			return this.get('assessment.data.contents')[this.get('currentSection')].slides[this.get('currentSlide')].title || "";
		}),

		slideDescription: Ember.computed('currentSection', 'currentSlide', function () {
			return this.get('assessment.data.contents')[this.get('currentSection')].slides[this.get('currentSlide')].description || "";
		}),

		slideQuestions: Ember.computed('currentSection', 'currentSlide', function () {
			var lookup = this.get('questionLookup');
			return this.get('model.questions')[this.get('sectionIndex')][this.get('currentSlide')];
		}),

		slideResponses: Ember.computed('updateFlag', 'sectionIndex', 'currentSlide', function () {
			return this.get('model.responses')[this.get('sectionIndex')][this.get('currentSlide')];
		}),

		onUpdateSlideResponses: Ember.observer('updateFlag', function () {
			//console.log('updating responses');
			var tracker;
			if (this.get('slide_is_completed').length == 0) {
				Ember.run.bind(this, this.get('initializeSlideTracker'))();
			}
			tracker = JSON.parse(JSON.stringify(this.get('slide_is_completed')));
			tracker[this.get('currentSection').toString()][this.get('currentSlide').toString()] = this.checkSlideCompleted(this.get('sectionIndex'), this.get('currentSlide'));
			this.set('slide_is_completed', tracker);
		}),

		// BOOLEANS

		canGoForward: Ember.computed('currentSection', 'currentSlide', function () {
			if (this.get('currentSlide') + 1 < this.get('currentSectionSlideCount')) return true;else if (this.get('currentSlide') + 1 == this.get('currentSectionSlideCount')) {
				if (this.get('currentSection') + 1 < this.get('totalSections')) return true;
			}
			return false;
		}),

		canGoBack: Ember.computed('currentSection', 'currentSlide', function () {

			if (this.get('currentSection') == 0 && this.get('currentSlide') - 1 < 0) return false;
			return true;
		}),

		checkSlideCompleted: function checkSlideCompleted(section, slide) {
			var slideQuestions = this.get('model.responses')[section][slide] || [];
			return slideQuestions.reduce(function (prev, current, list) {
				if (current === null) return false;
				if (current.value === null) return false;
				if (Ember.isArray(current.value) && current.value.length == 0) return false;
				return prev;
			}, true);
		},
		initializeSlideTracker: function initializeSlideTracker() {
			var tracker = this.get('model.assessment.data.contents').map(function (item) {
				return {};
			});
			var section_cursor = 0;
			var slide_cursor = 0;
			var section = this.get('model.assessment.data.contents').length - 1;
			var slide = this.get('model.assessment.data.contents')[section].slides.length;
			while (section_cursor < section || section_cursor == section && slide_cursor < slide) {

				tracker[section_cursor.toString()][slide_cursor.toString()] = this.checkSlideCompleted(section_cursor, slide_cursor);

				if (section_cursor < section) {
					if (slide_cursor < this.get('model.assessment.data.contents')[section_cursor].slides.length - 1) slide_cursor++;else {
						slide_cursor = 0;
						section_cursor++;
					}
				} else {
					if (slide_cursor < slide) slide_cursor++;else {
						section_cursor++;
					}
				}
			}
			tracker.unshift({ "0": [true] }); // accommodate introduction slide
			this.set('slide_is_completed', tracker);
		},


		actions: {
			save: function save() {
				this.set('isSaving', true);
				var self = this;

				(0, _ajax.default)(_environment.default.base_url + "/api/assessments/update/" + this.get('model.code'), {
					method: 'POST',
					body: JSON.stringify(this.get('model.responses')),
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
						'X-AppId': _environment.default.apiServer.headers['X-AppId']
					}
				}).then(function (data) {

					self.set('isModified', false);
					self.transitionToRoute('account');
				}).catch(function (err) {
					self.set('isSaving', false);
					err.json().then(function (body) {
						if ('error' in body) alert(body.error);else alert('There was an error submitting your assessment. Check your internet connection and try again.');
					});
				});
			},
			submit: function submit() {
				this.set('isSaving', true);
				var self = this;

				(0, _ajax.default)(_environment.default.base_url + "/api/assessments/update/" + this.get('model.code') + '?submit=true', {
					method: 'POST',
					body: JSON.stringify(this.get('model.responses')),
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
						'X-AppId': _environment.default.apiServer.headers['X-AppId']
					}
				}).then(function (data) {

					self.set('isModified', false);
					self.transitionToRoute('account');
				}).catch(function (err) {
					self.set('isSaving', false);
					err.json().then(function (body) {
						if ('error' in body) alert(body.error);else alert('There was an error submitting your assessment. Check your internet connection and try again.');
					});
				});
			},
			goToSlide: function goToSlide(section, slide) {
				this.set('currentSection', section);
				this.set('currentSlide', slide);
				this.get('scroller').scrollVertical('.application', { offset: 50 });
			},
			goForward: function goForward() {

				if (!this.get('canGoForward')) return;
				if (this.get('currentSlide') + 1 < this.get('currentSectionSlideCount')) this.incrementProperty('currentSlide');else {
					this.incrementProperty('currentSection');
					this.set('currentSlide', 0);
				}
				this.get('scroller').scrollVertical('.application', { offset: 50 });
			},
			goBack: function goBack() {
				if (!this.get('canGoBack')) return;
				if (this.get('currentSlide') - 1 >= 0) this.decrementProperty('currentSlide');else {

					this.decrementProperty('currentSection');
					this.set('currentSlide', this.get('currentSectionSlideCount') - 1);
				}
				this.get('scroller').scrollVertical('.application', { offset: 50 });
			},
			setResponse: function setResponse(section, slide, question, response) {
				this.set('model.responses.' + section + '.' + slide + '.' + question, response);
				this.incrementProperty('updateFlag');
				this.set('isModified', true);
				// trigger a change to update the slide's completion status
			}
		}

	});
});